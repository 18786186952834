import { Image, Box } from '@chakra-ui/react'

export enum TablePreviewSize {
  REGULAR,
  LARGE,
}

export const previewSizeToPixels = {
  [TablePreviewSize.REGULAR]: 70,
  [TablePreviewSize.LARGE]: 85,
}

const TablePreview = ({ src, previewSize = TablePreviewSize.REGULAR }) => {
  if (!src) return <></>

  return (
    <Box width={previewSizeToPixels[previewSize]}>
      <Image
        boxShadow={'l'}
        boxSize={previewSizeToPixels[previewSize]}
        // objectFit="cover"
        rounded={'md'}
        src={src}
      />
    </Box>
  )
}

export default TablePreview
