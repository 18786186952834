import {
  Archetype,
  Platform,
  PlatformSubtype,
  PlatformSubtypeId,
  ProductType,
} from '@/generated/enums'
import { ReportType } from '../constants'
import { formatSnakeStringWithCapitalization } from '../string_utils'
import { PLATFORM_TYPE_DISPLAY_MAP, REPORT_TYPES_WITH_PLATFORMS } from './report_utils'
import { PRODUCT_TO_DEFAULT_SUBTYPE } from '@/generated/platform_subtype_mapping'

export type PlatformSubtypeMetadata = {
  id: PlatformSubtypeId
  name: PlatformSubtype
  platform: Platform
  archetype: Archetype
  product: ProductType
}

export const PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE = 'Other'

export const getPlatformSubtypeDisplayValue = (
  subtype_name: string,
  reportType?: ReportType | undefined,
): string => {
  if (!subtype_name) return ''
  let displayAsOther = false
  if (reportType && REPORT_TYPES_WITH_PLATFORMS.includes(reportType)) {
    const product = PLATFORM_TYPE_DISPLAY_MAP[reportType] // convert between enums
    if (product && subtype_name == PRODUCT_TO_DEFAULT_SUBTYPE[product]) {
      displayAsOther = true
    }
  }
  return displayAsOther
    ? PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
    : formatSnakeStringWithCapitalization(subtype_name)
}
