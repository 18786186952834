import React from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { DetectionAssetType } from './detection_asset_type'
import { DetectionAssetAsset } from './detection_asset_asset'

export function DetectionAssetPreview({ detectionAsset }) {
  if (!detectionAsset) {
    return (
      <Text color={'#666'} fontSize={'small'}>
        n/a
      </Text>
    )
  }

  return (
    <HStack spacing="6px">
      <DetectionAssetType detectionAsset={detectionAsset} />

      <DetectionAssetAsset detectionAsset={detectionAsset} />
    </HStack>
  )
}
