import { DOPPEL_DARK_CLICKABLE_HOVER } from '@/utils/style'
import { Tag } from '@chakra-ui/react'

export function DetectionAssetType({ detectionAsset }) {
  return (
    <Tag
      bgColor={DOPPEL_DARK_CLICKABLE_HOVER}
      color={'#FFFFFF'}
      fontSize={12}
      width="fit-content"
    >
      {detectionAsset.type}
    </Tag>
  )
}
