import {
  Flex,
  Tr,
  Table,
  Tbody,
  Th,
  Thead,
  Heading,
  Td,
  Tooltip,
  HStack,
  Box,
} from '@chakra-ui/react'
import { DetectionAssetPreview } from './detection_asset_preview'
import { FeatureName } from '@/generated/enums'
import { Text } from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { useIsEmployeeView } from '@/hooks/id_token_claims'

const DETECTION_REASONS_PREVIEW_HEIGHT = '300px' // Set a fixed height for the logs container.

export const FEATURES_TO_RENDER = [
  FeatureName.SITE_TEXT_REGEX,
  FeatureName.SITE_TEXT_TFIDF,
  FeatureName.SITE_TEXT_DIFF_LIB,
  FeatureName.URL_SIMILARITY_FILTERING,
  FeatureName.URL_SIMILARITY_RANKING,
  FeatureName.URL_SIMILARITY_DOMAIN_SEARCH,
  FeatureName.URL_KEYWORD_SEARCH,
  FeatureName.URL_SIMILARITY_SECOND_LEVEL_DOMAIN_MATCH,
  FeatureName.URL_URL_SIMILARITY,
  FeatureName.HTML_BRAND_LOGO_SIMILARITY,
  FeatureName.HTML_FAVICON_SIMILARITY,
  FeatureName.HTML_TITLE_SIMILARITY,
  FeatureName.SCREENSHOT_SIMILARITY,
  FeatureName.PHISH_REPORT_DETECTION,
  FeatureName.SITE_HTML_REGEX,
  FeatureName.IP_ADDRESS_MALICIOUSNESS,
  FeatureName.SITE_URL_REGEX,
  FeatureName.HTML_FORM_DETECTION,
  FeatureName.TELEGRAM_LINK_DETECTION,
  FeatureName.URL_REDIRECT_DETECTION,
  FeatureName.SOCIAL_MEDIA_DISPLAY_NAME_SIMILARITY,
  FeatureName.SOCIAL_MEDIA_USERNAME_BRAND_NAME_SIMILIARITY,
  FeatureName.SOCIAL_MEDIA_USERNAME_SIMILARITY,
  FeatureName.SOCIAL_MEDIA_USER_PROFILE_IMAGE_SIMILARITY,
]

const FEATURES_TO_TOOLTIP_TEXT = {
  [FeatureName.SITE_TEXT_REGEX]:
    'The site text regex feature detected a high similarity between the site text of the original and the site text of a Protected Asset.',
  [FeatureName.SITE_TEXT_TFIDF]:
    'The site text TF-IDF feature detected a high similarity between the site text of the original and the site text of a Protected Asset.',
  [FeatureName.SITE_TEXT_DIFF_LIB]:
    'The site text diff lib feature detected a high similarity between the site text of the original and the site text of a Protected Asset.',
  [FeatureName.URL_SIMILARITY_FILTERING]:
    'The URL Similarity Filtering feature detected a high similarity between the URL of the original and the URL of a Protected Asset.',
  [FeatureName.URL_SIMILARITY_RANKING]:
    'The URL Similarity Ranking feature detected a high similarity between the URL of the original and the URL of a Protected Asset.',
  [FeatureName.URL_SIMILARITY_DOMAIN_SEARCH]:
    'The URL Domain Search feature detected a high similarity between the URL of the original and the domain name of a Protected Asset.',
  [FeatureName.URL_KEYWORD_SEARCH]:
    'The URL Keyword Search feature detected a high similarity between the URL of the original and a user-defined keyword for the brand.',
  [FeatureName.URL_SIMILARITY_SECOND_LEVEL_DOMAIN_MATCH]:
    'The URL Second Level Domain Match feature detected a high similarity between the second level domain of the original and a Protected Asset.',
  [FeatureName.URL_URL_SIMILARITY]:
    'The URL Similarity feature detected a high similarity between the URL of the original and the URL of a Protected Asset.',
  [FeatureName.HTML_BRAND_LOGO_SIMILARITY]:
    'The HTML Brand Logo Similarity feature detected a high similarity between the brand logo of the original and the brand logo of a Protected Asset.',
  [FeatureName.HTML_FAVICON_SIMILARITY]:
    'The HTML Favicon Similarity feature detected a high similarity between the favicon of a Protected Asset.',
  [FeatureName.HTML_TITLE_SIMILARITY]:
    'The HTML Title Similarity feature detected a high similarity between the title of the original and the title of a Protected Asset.',
  [FeatureName.SCREENSHOT_SIMILARITY]:
    'The Screenshot Similarity feature detected a high similarity between the screenshot of the original and the screenshot of a Protected Asset.',
  [FeatureName.PHISH_REPORT_DETECTION]:
    'The Phishing Detection feature detected common attributes of phishing on the original site.',
  [FeatureName.SITE_HTML_REGEX]:
    'The html regex feature detected a high similarity between the site html of the original and a custom regex rule.',
  [FeatureName.IP_ADDRESS_MALICIOUSNESS]:
    'The Malicious Source IP feature detected a high similarity between the source IP of the original and a known malicious IP address for this brand.',
  [FeatureName.SITE_URL_REGEX]:
    'The site url regex feature detected a high similarity between the site url of the original and a custom regex rule.',
  [FeatureName.HTML_FORM_DETECTION]:
    'The HTML Form Detection feature detected a potentially malicious form on the original site.',
  [FeatureName.TELEGRAM_LINK_DETECTION]:
    'The feature detected a potentially malicious Telegram link on the original site.',
  [FeatureName.URL_REDIRECT_DETECTION]:
    'The feature detected a URL redirect on the original site.',
  [FeatureName.SOCIAL_MEDIA_DISPLAY_NAME_SIMILARITY]:
    'The feature detected a similarity between the social media display name of the original and the social media display name of a Protected Asset.',
  [FeatureName.SOCIAL_MEDIA_USERNAME_BRAND_NAME_SIMILIARITY]:
    'The feature detected a similarity between the social media display name of the original and the brand name of the client.',
  [FeatureName.SOCIAL_MEDIA_USERNAME_SIMILARITY]:
    'The feature detected a similarity between the username of the original and the username of a protected asset.',
  [FeatureName.SOCIAL_MEDIA_USER_PROFILE_IMAGE_SIMILARITY]:
    'The feature detected a similarity between the profile image of the original and the profile image of a protected asset.',
}

export function DetectionReasonsTable({ ml_features, match_attributions }) {
  const [isEmployeeView] = useIsEmployeeView()

  const filteredMlFeatures = ml_features
    .filter((ml_features) => FEATURES_TO_RENDER.includes(ml_features.name))
    // Only show high scoring features
    .filter((ml_feature) => ml_feature.value >= ml_feature.threshold)
    // Show features by highest score
    .sort((a, b) => b.value - a.value)

  const sourcingQueryAttributions = match_attributions.filter(
    (match_attributions) =>
      match_attributions.attribution_type == 'sourcing_query' &&
      match_attributions.sourcing_queries !== null,
  )

  const display_ml_features = filteredMlFeatures.length > 0
  const display_sourcing_query_attribution =
    sourcingQueryAttributions.length > 0 && isEmployeeView

  if (!display_ml_features && !display_sourcing_query_attribution) {
    return null
  }
  return (
    <Flex direction="column" paddingBottom={4} width="100%">
      <Flex alignItems="center" marginBottom={2}>
        <Heading as="h4" mr={2} size="md">
          Detection Reasons
        </Heading>
      </Flex>

      <Flex
        direction="column"
        maxHeight={DETECTION_REASONS_PREVIEW_HEIGHT}
        overflowY="auto"
      >
        {display_ml_features && (
          <Table size="sm" variant="simple">
            <Thead>
              <Tr>
                <Th>Feature Name</Th>

                <Th>Detection Asset</Th>

                {isEmployeeView ? <Th>Score</Th> : null}
              </Tr>
            </Thead>

            <Tbody>
              {filteredMlFeatures.map((ml_feature, index) => (
                <Tr key={`state-change-${index}`}>
                  <Td>
                    <HStack spacing="6px">
                      <Text>{ml_feature.name}</Text>

                      <Tooltip label={FEATURES_TO_TOOLTIP_TEXT[ml_feature.name]}>
                        <InfoOutlineIcon />
                      </Tooltip>
                    </HStack>
                  </Td>

                  <Td>
                    <DetectionAssetPreview
                      detectionAsset={ml_feature.detection_asset}
                    />
                  </Td>

                  {isEmployeeView ? <Td>{ml_feature.value}</Td> : null}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}

        {display_sourcing_query_attribution && (
          <Box marginTop={4}>
            <Table size="sm" variant="simple">
              <Thead>
                <Tr>
                  <Th>Sourcer Label</Th>

                  <Th>Sourcing Query</Th>

                  <Th>Force Create Alert</Th>
                </Tr>
              </Thead>

              <Tbody>
                {sourcingQueryAttributions.map((match_attribution, index) => (
                  <Tr key={`state-change-${index}`}>
                    <Td>
                      <HStack spacing="6px">
                        <Text>{match_attribution.sourcing_queries?.sourcer_label}</Text>
                      </HStack>
                    </Td>

                    <Td>
                      <Text>{match_attribution.sourcing_queries?.query}</Text>
                    </Td>

                    <Td>
                      {match_attribution.sourcing_queries?.force_create_report
                        ? 'True'
                        : 'False'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
